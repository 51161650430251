import React from "react";
import { Sidebar } from "flowbite-react";
import { HiOutlineCalendar } from "react-icons/hi";
import { Link } from "gatsby";

export default function SideNav() {
  return (
    <>
    <Sidebar aria-label="Default sidebar" className="w-full sidebar">
      <Sidebar.Items>
        <h2 className="px-4">Key dates</h2>
        <Sidebar.ItemGroup>
          <Sidebar.Item icon={HiOutlineCalendar} className="hover:no-underline">
            <Link to="/registration">Early bird registration opens</Link><br/>
            <b className="text-navy">May 2024</b>
          </Sidebar.Item>
          <Sidebar.Item icon={HiOutlineCalendar} className="hover:no-underline">
            <Link to="/registration">Early bird registration closes</Link><br/>
            <b className="text-navy">30 June 2024</b>
          </Sidebar.Item>
          <Sidebar.Item icon={HiOutlineCalendar} className="hover:no-underline">
            <Link to="/registration">Standard registration opens</Link><br/>
            <b className="text-navy">1 July 2024</b>
          </Sidebar.Item>
          <Sidebar.Item icon={HiOutlineCalendar} className="hover:no-underline">
            <Link to="/accommodation">Discounted accommodation booking deadline</Link><br/>
            <b className="text-navy">16 September 2024</b>
          </Sidebar.Item>
          <Sidebar.Item icon={HiOutlineCalendar} className="hover:no-underline">
            International Conference of Legal Regulators and Conference of Regulatory Officers<br/>
            <b>16–18 October 2024</b>
          </Sidebar.Item>
        </Sidebar.ItemGroup>
      </Sidebar.Items>
    </Sidebar>
    </>
  );
}
