import React, { useState, useEffect } from 'react';

function CountdownTimer({ targetDate }) {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, '0'),
        minutes: String(Math.floor((difference / 1000 / 60) % 60)).padStart(2, '0'),
        seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, '0'),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="flex flex-row">
      {timeLeft.days ? (
        <div className="time-container text-navy text-center mx-3">
          <div className="time text-[3rem] xl:text-[5rem] font-medium bebas-neue-regular">{timeLeft.days}</div>
          <div className="date-text font-light capitalize bebas-neue-regular text-lg">days</div>
        </div>
      ) : null}

      {timeLeft.hours ? (
        <div className="time-container text-navy text-center mx-3">
          <div className="time text-[3rem] xl:text-[5rem] font-medium bebas-neue-regular">{timeLeft.hours}</div>
          <div className="date-text font-light capitalize bebas-neue-regular text-lg">hours</div>
        </div>
      ) : null}

      {timeLeft.minutes ? (
        <div className="time-container text-navy text-center mx-3">
          <div className="time text-[3rem] xl:text-[5rem] font-medium bebas-neue-regular">{timeLeft.minutes}</div>
          <div className="date-text font-light capitalize bebas-neue-regular text-lg">minutes</div>
        </div>
      ) : null}

      {timeLeft.seconds ? (
        <div className="time-container text-navy text-center mx-3">
          <div className="time text-[3rem] xl:text-[5rem] font-medium bebas-neue-regular">{timeLeft.seconds}</div>
          <div className="date-text font-light capitalize bebas-neue-regular text-lg">seconds</div>
        </div>
      ) : null}
    </div>
  );

}

export default CountdownTimer;