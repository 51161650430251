import React from 'react';
import { Footer } from 'flowbite-react';
import { Link } from 'gatsby';
import backgroundImage from '../../static/images/bg-pattern.webp';

const footerSections = [
    {
        title: "Registration",
        links: [
            { name: "Registration", href: "/registration" },
            { name: "Accommodation", href: "/accommodation" },
            { name: "Getting a Visa", href: "/visa" }
        ]
    },
    {
        title: "Destination",
        links: [
            { name: "Our Venue and Melbourne", href: "/our-venue-and-melbourne" },
            { name: "Visit Victoria", href: "/visit-victoria" }
        ]
    },
    {
        title: "Program",
        links: [
            {
                name: "Program Overview",
                href: "/program",
            },
            {
                name: "Our Speakers",
                href: "/our-speakers",
            },
        ]
    },
    {
        title: "Contact Us",
        links: [
            { name: "Contact Us", href: "/contact" },
        ]
    },
];


export default function FooterNav() {
    return (
        <Footer container className="bg-repeat rounded-none" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="container mx-auto w-full">
                <div className="flex flex-col gap-2 justify-between space-y-4 md:flex-row md:space-y-0">
                    <Link to="/">
                        <img
                            src="/images/ICLR-CORO2024-logo-REV.svg"
                            width={400}
                            placeholder='blurred'
                            alt="ICLR CORO2024 logo reversed"
                            quality={100}
                        />

                    </Link>
                    <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
                        {footerSections.map(section => (
                            <div key={section.title}>
                                <Footer.Title title={section.title} className="text-white text-xl" />
                                <Footer.LinkGroup col>
                                    {section.links.map(link => (
                                        <Link key={link.name} to={link.href} className="text-white">{link.name}</Link>
                                    ))}
                                </Footer.LinkGroup>
                            </div>
                        ))}
                    </div>
                </div>
                <div className=" pt-8 pb-4 text-white">
                    <p>The organisers of the combined International Conference of Legal Regulators and Conference of Regulatory Officers acknowledge the Traditional Owners of Country throughout Australia and recognise their continuing connection to lands, waters and communities. We pay our respect to Aboriginal and Torres Strait Islander cultures, and to Elders past and present.</p>
                </div>
                <Footer.Divider />
                <div className="flex items-center justify-between">
                    <Footer.Copyright href="https://icmsaust.com.au" by="ICMS Australasia™" year={2024} className="text-white" />

                </div>
            </div>
        </Footer>
    );
}
